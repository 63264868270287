.mainUserContainer{
    display: flex;
    padding-left: 10px;
}


.mainUser{
display: flex;
justify-content: space-around;
}
.panelDivBoard{
border: 2.4px dotted #6443e7;;
display: flex;
flex-direction: row;
align-items: flex-start;
padding-left: 10px;
gap: 20px;
width: 250px;
background-color: #F7F9FC;
margin: 4px;
}



#userBordData{
font-size: 30px;
}
#userBoardCaption{
font-size: 15px;
}


.twoBtns{
    width:90%;
    margin: 20px;
    display: flex;
    row-gap: 10px;
    justify-content: space-between;;
}



.userPanalDetailsHeading{
display: flex;
justify-content: space-between;
box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

}
.PanalDetailsSearch input{
    margin-top: 4px;
    height: 30px;
    font-size: 19px;
}

.PanalDetailsLinks{
    display: flex;
    justify-content: space-between;
    width: 700px;
font-size: 19px;
    margin: 8px;

}

.PanalDetailsLinks span:hover {
    background-color: rgb(144, 147, 156);
    border-radius: 5px;
  }
  



#pageNo{
    font-size: 24px;
    border-radius: 51%;
    background-color: #1DA1F2;
    color: white;
}


.pagination{
    text-align: center;
}

table, th, td {
    padding: 2px;
    background-color: rgb(248, 244, 244);
  }

  .blurForms {
    position: fixed;
    top: 32%;
    left: 35%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    overflow: hidden;
    width: 320px;
    background: white;
  }
th{
    background-color: #eaf5fc;
    
}
  table{
    width: 100%;
    text-align: center; 
  }

  .title{

    background: #1DA1F2;
    padding: 7px;    
    color:white  

}
#decisionUser{
  width: 120px;
  height: 30px;
  font-size: 15px;
  /* margin-top: 22px; */
  margin-bottom: 2px;
}