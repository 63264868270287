.StoreListNav{
    display: column;
 width: 70vw;
    justify-content: space-between;
    background-color: #b6d2f0;
}

.StorePanalDetailsList1{
    display: flex;
    justify-content: space-around;
}






.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .popUpContainer {
    background-color: #ffffff;
    max-width: 600px; /* Adjust the max width as needed */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
   

  }
  .popUpContainer .actionButton {
    display: none;
  }
  
 
  
  .popup p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .popup button {
    padding: 10px 20px;
    background-color: rgb(255, 87, 34);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #0056b3;
  }
  

  .Productform{
    margin: 8px 0px;
    /* display: flex; */
    justify-content: space-around;
    font-size: 18px;
  
  }

  .Nameform{
    margin: 8px 0px;
    /* display: flex; */
    justify-content: space-around;
    font-size: 18px;

  }
  .Catform{
    margin: 8px 0px;
    /* display: flex; */
    justify-content: space-around;
    font-size: 18px;

  }
  .day1form{
    margin: 8px 0px;
    /* display: flex; */
    justify-content: space-around;
    font-size: 18px;

  }
  .day2form{
    margin: 8px 0px;
    /* display: flex; */
    justify-content: space-around;
    font-size: 18px;

  }
  .day3form{
    margin: 8px 0px;
    /* display: flex; */
    justify-content: space-around;
    font-size: 18px;

  }
  .Actionform{
    margin: 8px 0px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;

  }

  .popUpContainer form {
    display: flex;
    flex-direction: column;
  }
  
  .popUpContainer label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .popUpContainer input{
    padding: 8px 0px;
    margin-bottom: 10px;

  }

  .popUpContainer select {
    padding: 8px;
    margin-bottom: 10px;
  }
  
#topTextStore{
  font-size: larger;
  padding: 0px 10px;
}
  #AddStore1Btn{
    background-color: #1DA1F2;
    color: white;
    border: none;
    border-radius: 2px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }


  