.RewardConatinerMAim{
  width: 100%;
}

.RewardListNav{
  display: flex;
width: 80vw;
  justify-content: space-between; 
  background-color: #b6d2f0;
}


.popOfButtons{
  display: flex;
  justify-content: space-between;
}

#Addreward1Btn{
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 2px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.RewardPanalDetailsList{
background-color: #96bbe2;
}

.RewardPanalDetailsList{
  display: flex;
  justify-content: space-around;
}

.myRewardDiv{
  display: flex;
  justify-content: space-around;
}

.popupRewad{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popUpContainerReward{
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  text-align: left;
}

.h3head{
  background-color: #007bff;
  color: white;
  padding: 10px;
}

#rewadBtn{
  background-color: #1DA1F2;
  color: white;
  border: none;
  border-radius: 2px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}