


.MyAdminCard{
    border: 2px solid rgb(27, 103, 244);
    width: 250px;
    height: auto;
    margin: 0px;
    /* padding: 10px;  */
}  
.MyAdminCard h4{
    max-width: 220px;

    width: 200px;
    background-color:'red'
}

.aghoriiiCards{
    
    display: flex;
    overflow: auto;
    white-space: nowrap;
}

.profilePic{
    position: relative;
    bottom: -30px;
    left: -250px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.socailLinkCard{
    width: 150px;
    display: flex;
    justify-content: space-around;
    font-size: 22px;
    position: relative;
    bottom: 105px;
    left: 90px;
}


.formAdminSub{
    padding: 5px;
    margin: 35px;
    border: 2px solid rgb(30, 115, 242);
    /* width: 200px */
}





.formAdminSub >div >input{
    width: 80vw;
    margin: 8px 0px;
    

}

.formAdminSub >div >select{
    width: 80vw;
    margin: 8px 0px;
    


}




.subAdminBtn{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    margin: 8px 0px;

}

.formA{
    width:600px;
}

.fromA input{
    width:10px
}
.fromA label{
    font-size: 10px;
    font-weight: bold;
}